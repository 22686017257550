import styled from 'styled-components'
import { Box, Flex, Link } from '@pancakeswap/uikit'
import Footer from 'components/Menu/Footer'
import { PageMeta } from 'components/Layout/Page'

const StyledPage = styled.div<{ $removePadding: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ $removePadding }) => ($removePadding ? '0' : '16px')};
  padding-bottom: 0;
  min-height: calc(100vh - 121px);
  margin-top: 48px;
  // background: ${({ theme }) => theme.colors.backgroundAlt};

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ $removePadding }) => ($removePadding ? '0' : '24px')};
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: ${({ $removePadding }) => ($removePadding ? '0' : '32px')};
    padding-bottom: 0;
  }
`

const StyledImg = styled.img`
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 60%;
  }
`

const Page: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { removePadding?: boolean; hideFooterOnDesktop?: boolean }
> = ({ children, removePadding = false, hideFooterOnDesktop = false, ...props }) => {
  return (
    <>
      <PageMeta />
      <StyledPage $removePadding={removePadding} {...props}>
        {children}
        <Flex flexGrow={1} />
        <Box
          display={['block', null, null, hideFooterOnDesktop ? 'none' : 'block']}
          width="100%"
          style={{ justifyContent: 'center' }}
        >
          {/* <Footer /> */}
          {/* <Link
            small
            external
            ellipsis
            href="https://bet.hodlx.exchange/rangefinder/hodlx"
            style={{ justifyContent: "center", width: "auto" }}
          >
            <StyledImg src="/images/hodl_bet.gif" style={{ marginBottom: "50px", marginTop: "50px" }} alt='' />
          </Link> */}
        </Box>
      </StyledPage>
    </>
  )
}

export default Page
